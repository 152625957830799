import "./App.css";

import Layout from "./comps/layout/Layout";

function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
